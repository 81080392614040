import React from 'react';
import BulletPoint from '../bulletPoint/bulletPoint';

const bulletPointList = (props) => {
    const data = props.data;
    console.log(data);
    return (
        <>
            <div className="bulletPointItem"><BulletPoint text={data.text[0]} heading={data.headings[0]} image={data.images[0]} /></div>
            <div className="bulletPointItem"><BulletPoint text={data.text[1]} heading={data.headings[1]} image={data.images[1]} /></div>
            <div className="bulletPointItem"><BulletPoint text={data.text[2]} heading={data.headings[2]} image={data.images[2]} /></div>
        </>
    ); 
}

export default bulletPointList;