import React from 'react';

const hero = (props) => {

    const backgroundConfig = {
        backgroundImage: `url(` + props.image + `)`
    };

    return (
        <div style={backgroundConfig}  className="hero">
            <div className="hero__container">
                <div className="hero__textBox">
                    { props.children }
                </div>
            </div>
        </div>
    );
};

export default hero;
