import React from 'react';
import '../../styles/main.scss';
import TopBar from '../topbar/topbar';
import MobileNav from '../mobileNav/mobileNav';
import Helmet from "react-helmet"
import { Link } from 'gatsby';

import featuredInImage from '../../assets/featured-companies.png';
import SocialBar from '../socialBar/socialBar';
import favicon from '../../assets/fav-icon.png';
import livitLogo from '../../assets/livit.svg'
import livitLogoPng from '../../../static/livit.png';

const layout = (props) => {
    return (
        <>
            <Helmet
                title="LIVIT"
                link={[
                    { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }
                ]}>
                <meta property="og:url" content="https://mylivit.com" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="MyLivit" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="https://mylivit.com/livit.png" />
                <meta property="fb:app_id" content="2012851835447784" />
            </Helmet>
            <TopBar className="desktopNav" />
            <MobileNav className="mobileNav" />
            { props.children }
            <section className="social">
                <SocialBar />
            </section>
            <footer className="footer">
                <div className="footer__logoContainer">
                    <img src={livitLogo} className="footer__logo" />
                </div>
                <div className="footer__content">
                    <div className="footer__navigation">
                        <ul className="footer__list">
                            <li className="footer__item">&copy;2018 SharkDreams</li>
                            <li className="footer__item">
                                <Link to="/privacy-policy" className="footer__link">Privacy Policy</Link>
                            </li>
                            <li className="footer__item">
                                <Link to="/terms-of-use" className="footer__link">Terms of Use</Link>
                            </li>
                            <li className="footer__item footer__item--contact">
                                For MY LIVIT registration inquires please call <a href="tel:844-695-4848" className="footer__link footer__link--simple">844-695-4848</a> or email <a href="mailto:contact@mylivit.com" className="footer__link footer__link--simple">contact@mylivit.com</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default layout;
