import React from 'react';
import Slider from 'react-slick';

import iphoneBorder from '../../assets/iphone.png';
import appScreen1 from '../../assets/app-screen-1.png';
import appScreen2 from '../../assets/app-screen-2.png';
import appScreen3 from '../../assets/app-screen-3.png';

const appSlider = () => {
    const sliderConfig = {
        dots: false,
        autoplay: true,
        autoplaySpeed: 1500,
    };

    return (
        <div className="phoneSlider">
            <div className="phoneSlider__app">
                <Slider {...sliderConfig}>
                    <div>
                        <img src={appScreen1} />
                    </div>
                    <div>
                        <img src={appScreen2} />
                    </div>
                    <div>
                        <img src={appScreen3} />
                    </div>
                </Slider>
            </div>
            <div className="phoneSlider__border">
                <img src={iphoneBorder} />
            </div>
        </div>
    );
}

export default appSlider;
