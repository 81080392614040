import React from 'react';

import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from '../button/button';

const navBar = () => {
  return (
    <div className="navBar">
      <ul className="navBar__list">
        <li className="navBar__listItem"><a className="navBar__listLink" href="/#device">DEVICE</a></li>
        <li className="navBar__listItem"><a className="navBar__listLink" href="/#app">APP</a></li>
        <li className="navBar__listItem"><a className="navBar__listLink" href="/#patch">PATCH</a></li>
      </ul>
    </div>
  );
};

export default navBar;
