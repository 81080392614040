import React from 'react';

const industryBox = (props) => {
  return (
    <div className="industryBox">
      <div className="industryBox__imageContainer">
        <img className="industryBox__image" src={props.image} alt="icon" />
      </div>
      <h3 className="industryBox__heading heading-tertiary">{ props.title }</h3>
      { props.children }
    </div>
  )

};

export default industryBox;
