import React from "react"
import '../styles/main.scss';

import Hero from '../components/hero/hero';
import Button from '../components/button/button';
import IndustryBox from '../components/industryBox/industryBox';
import AppSlider from '../components/appSlider/appSlider'
import BulletPointList from '../components/bulletPointList/bulletPointList'
import SocialBar from '../components/socialBar/socialBar'
import Layout from '../components/layout/layout'

import pillBottleImage from '../assets/livit-pill-bottle.jpg';
import livitLogo from '../assets/livit.svg';
import ipadImage from '../assets/ipad.png';
import heroImage from '../assets/hero.jpg';
import smartTrackingImage from '../assets/featured-1.jpg';
import vitalMonitoringImage from '../assets/featured-2.jpg';
import interactiveClinicImage from '../assets/featured-3.jpg';
import iconCheck from '../assets/icon-check.jpg';
import iconLightning from '../assets/icon-lightning.jpg';
import iconTarget from '../assets/icon-target.jpg';
import iconMedical from '../assets/icon-medical.jpg';
import iconCalendar from '../assets/icon-calendar.jpg';
import iconLightbulb from '../assets/icon-lightbulb.jpg';
import googlePlayDownloadButton from '../assets/google-play-icon.png'
import appStoreDownloadButton from '../assets/app-store-icon.png'

import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';

configureAnchors({offset: -120});

const homePage = () => {
    return (
        <>
            <Layout>
                <Hero image={heroImage}>
                    <h1 className="heading-primary u-margin-bottom-very-small">
                        <span className="heading-primary--main">It's a New Era</span>
                        <span className="heading-primary--main">in Healthcare</span>
                    </h1>
                    <p className="paragraph paragraph--bold">
                        Digital technology is making healthcare simpler, instead of complicated. Bringing patients and
                        providers closer, more connected. Precision care. Focusing on the needs of the individual.
                    </p>
                    <p className="paragraph paragraph--bold">
                        A better way of living. Made possible by LIVIT.
                    </p>
                </Hero>
                <main>
                    <section className="industries">
                        <div className="industries__list">
                            <div className="industry__item">
                                <IndustryBox image={smartTrackingImage} title="Smart Tracking">
                                    <p className="paragraph">
                                        The LIVIT smart device tracks your medication use. Helping you take the right 
                                        dosage at the right times.
                                    </p>
                                </IndustryBox>
                            </div>
                            <div className="industry__item">
                                <IndustryBox image={vitalMonitoringImage} title="Vital Monitoring">
                                    <p className="paragraph">
                                        The LIVIT patch measures your body’s response to the medicine by monitoring vital 
                                        signs specific to your treatment.
                                    </p>
                                </IndustryBox>
                            </div>
                            <div className="industry__item">
                                <IndustryBox image={interactiveClinicImage} title="Interactive Clinic">
                                    <p className="paragraph">
                                        The LIVIT mobile and web apps let your healthcare providers, and you, see 
                                        your progress in real time.
                                    </p>
                                </IndustryBox>
                            </div>
                        </div>
                    </section>
                    <section className="mission">
                        <div className="mission__content">
                            <div className="u-center-text u-margin-bottom-very-small">
                                <h2 className="heading-secondary split-heading">Digital Clinic. <span>Patient Centered.</span></h2>
                            </div>
                            <div className="mission__writtenContent">
                                <p className="paragraph">
                                    LIVIT is more than a device, patch, and an app. It’s a new approach to virtual care. 
                                    It’s a digital clinic that lets you and your healthcare providers see your medication 
                                    use and health data instantly, continuously, and in real time. So you can make better, 
                                    more informed decisions. Together.
                                </p>
                            </div>
                            <div className="mission__videoContainer">
                                <iframe width="560" 
                                    src="https://www.youtube.com/embed/TolBV9ufFLU?rel=0&showinfo=0&modestbranding=0" 
                                    frameBorder="0" 
                                    allow="encrypted-media" 
                                    allowFullScreen>
                                </iframe>
                            </div>
                        </div>
                    </section>
                    <section className="products">
                        <ScrollableAnchor id={'device'}>
                            <div className="products__content-2">
                                <div className="products__display products__display--images--ipad">
                                    <div className="products__ipadContainer">
                                        <img src={pillBottleImage} className="products__ipad" />
                                    </div>
                                </div>
                                <div className="products__display">
                                    <BulletPointList data={{
                                            headings: ['Small but Powerful', 'Accurate, Secure Detection', 'Easy to Use'],
                                            images: [iconLightning, iconTarget, iconLightbulb],
                                            text: [
                                                'The LIVIT device is so small it’s barely noticeable. It’s universal, disposable, and lasts the duration of the prescription.',
                                                'The LIVIT device accurately detects medicine consumption, and securely transfers usage data to the system for private access by the patient and provider.',
                                                'LIVIT comes installed on your medication packaging. All you need to do is take your medicine as prescribed, and the technology does the rest.'
                                            ]
                                        }} />
                                </div>
                            </div>
                        </ScrollableAnchor>
                            <div className="products__content-1">
                                <ScrollableAnchor id={'app'}>
                                    <div className="scrollToPoint"></div>
                                </ScrollableAnchor>
                                <div className="products__display">
                                    <BulletPointList data={{
                                            headings: ['Provider-Patient Connection', 'Alerts & Reminders', 'Compliance Monitoring'],
                                            images: [iconMedical, iconCalendar, iconCheck],
                                            text: [
                                                'The LIVIT app connects you and your healthcare providers. Together, you can review your health, conduct live chats, and even update prescriptions. All without an office visit.',
                                                'If you take multiple medications, the LIVIT app can send reminders to you so you never forget. The app also alerts you to any changes in your prescription or dosage.',
                                                'Your healthcare provider can see your daily dosage intervals, so they can be sure you are taking medications as prescribed and your treatment is working.'
                                            ]
                                        }} />
                                </div>
                                <div className="products__display products__display--images--phone">
                                    <div className="products__phoneSlider">
                                        <AppSlider></AppSlider>
                                    </div>
                                </div>
                            </div>
                    </section>
                    <section className="vitalMonitoring">
                            <div className="vitalMonitoring__content">
                                <div className="u-center-text u-margin-bottom-very-small">
                                    <ScrollableAnchor id={'patch'}>
                                        <h2 className="heading-secondary">Vital Monitoring</h2>
                                    </ScrollableAnchor>
                                </div>
                                <div className="vitalMonitoring__writtenContent">
                                    <p className="paragraph">
                                        Is your medication working? Is the dosage right? What happens if you miss a dose? The LIVIT patch takes 
                                        the guesswork out of those questions by monitoring your vital signs. Customized to you and your treatments, 
                                        it gives you and your healthcare providers greater visibility into your medications and how they’re 
                                        affecting your health. More information. Fast. Precise. Secure.
                                    </p>
                                </div>
                            </div>
                    </section>
                    <section className="download">
                        <div className="download__content">
                            <h2 className="heading-secondary heading-secondary--thin">Download the App</h2>
                            <p className="paragraph">
                                LIVIT is designed to be easy to use. To get started, simply download the app, 
                                and your pharmacist will take care of the rest.
                            </p>
                            <div className="download__storeContainer">
                                <a href="https://itunes.apple.com/us/app/livit-by-sharkdreams/id1273626422" target="_blank"><img src={appStoreDownloadButton} className="download__storeImage" /></a>
                            </div>
                            <div className="download__storeContainer">
                                <a href="https://play.google.com/store/apps/details?id=com.sharkdreams.livit" target="_blank"><img src={googlePlayDownloadButton} className="download__storeImage" /></a>
                            </div>
                        </div>
                    </section>
                </main>
            </Layout>
        </>
    );
};

export default homePage;

