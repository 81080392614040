import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'gatsby';

const socialBar = () => {
    return (
        <div className="socialBar">
            <a href="https://twitter.com/shark_dreams_co" target="_blank"><FontAwesomeIcon icon={faTwitter} size="2x" /></a>
            <a href="https://www.linkedin.com/company/sharkdreams" target="_blank"><FontAwesomeIcon icon={faLinkedinIn} size="2x" /></a>
            <a href="https://www.instagram.com/sharkdreamsco/" target="_blank"><FontAwesomeIcon icon={faInstagram} size="2x" /></a>
        </div>
    );
}

export default socialBar;
