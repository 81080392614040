import React from 'react';

const bulletPoint = (props) => {
    return (
        <div className="bulletPoint">
            <div className="bulletPoint__iconContainer">
                <img src={props.image} className="bulletPoint__icon" />
            </div>
            <div className="bulletPoint__info">
                <h5 className="bulletPoint__heading">{props.heading}</h5>
                <p className="bulletPoint__text">{props.text}</p>
            </div>
        </div>
    );
}

export default bulletPoint;