import React from 'react';
import { Link } from 'gatsby';

const mobileNav = props => {
    let classes = 'navigation';

    if (props.className) {
        classes = 'navigation' + ' ' + props.className;
    }
    
    return (
        <div className={classes}>
            <input
                type="checkbox"
                className="navigation__checkbox"
                id="navigation-toggle"
            />
            <label className="navigation__button" htmlFor="navigation-toggle">
                <span className="navigation__icon" />
            </label>
            <div className="navigation__background">&nbsp;</div>
            <nav className="navigation__nav">
                <ul className="navigation__list">
                    <li className="navigation__item">
                        <a href="/#device" 
                            className="navigation__link"
                            onClick={ () => {
                                try {
                                    const checkbox = document.getElementById('navigation-toggle');
                                    const checkboxState = checkbox.checked
                                    checkbox.checked = !checkbox.checked
                                } catch(err) {}
                            } }>
                            DEVICE
                        </a>
                    </li>
                    <li className="navigation__item">
                        <a href="/#app" 
                            className="navigation__link"
                            onClick={ () => {
                                try {
                                    const checkbox = document.getElementById('navigation-toggle');
                                    const checkboxState = checkbox.checked
                                    checkbox.checked = !checkbox.checked
                                } catch(err) {}
                            } }>
                            APP
                        </a>
                    </li>
                    <li className="navigation__item">
                        <a href="/#patch"
                            className="navigation__link"
                            onClick={ () => {
                                try {
                                    const checkbox = document.getElementById('navigation-toggle');
                                    const checkboxState = checkbox.checked
                                    checkbox.checked = !checkbox.checked
                                } catch(err) {}
                            } }>
                            PATCH
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default mobileNav;
